<template>
  <div :class="classes">
    <slot />
  </div>
</template>

<script setup lang="ts">
import { twMerge } from "tailwind-merge";

interface SkeletonProps {
  variant?: "paragraph" | "h1" | "h2" | "h3";
  class?: string;
}

const { variant = "paragraph", ...props } = defineProps<SkeletonProps>();

const classes = computed(() =>
  twMerge(
    "bg-neutral-100 mb-2 h-3 rounded animate-pulse",
    variant === "h1" && "h-10",
    variant === "h2" && "h-8",
    variant === "h3" && "h-6",
    variant === "paragraph" && "h-5",
    props.class
  )
);
</script>
